<template>
	<section class="views" @click="schoolNameList = []">
		<headers :isBack="false" title="報名資料"></headers>
		<div class="views_content">
			<div class="views_content_box">
				<ul>
					<li>
						<div class="list2">
							<div class="list2_uls">
								<div class="listLeft">參賽者姓名</div>
								<div class="listRight">
									<input type="text" placeholder="請輸入" v-model.trim="from.username" @blur="onBlurInput()"/>
								</div>
							</div> 

							<div class="beizhu">*須與身份證上的姓名一致</div>
						</div>
					</li>
					<li>
						<div class="list">
							<div class="listLeft">性别</div>
							<div class="listRight">
								<div class="listRight_xuan" @click="sexBut">
									<span :class="
                      from.sex == '請選擇' ? '' : 'placeholderValue'
                    ">{{ this.from.sex }}</span>
									<span class="listRight_icon"
										:class="{ '': true, listRight_icontransform: showPicker }">
										<van-icon name="arrow-down" size="12" color="#333333" />
									</span>
								</div>
							</div>
						</div>
					</li>
					<template v-if="!schoolCode">
					<li>
						<div class="list">
							<div class="listLeft">手機號</div>
							<div class="listRight">
								<div class="listRight_sji">
									<div class="left" @click="pickerPhoneBut">
										<span>{{ from.code }}</span>
										<span :class="{ '': true, left_formTwo: showPickerTwo }">
											<van-icon name="arrow-down" size="12" color="#333333" />
										</span>
									</div>

									<div class="right">
										<input type="number" placeholder="請輸入" v-model.trim="from.mobilePhone" />
									</div>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">驗證碼</div>
							<div class="listRight">
								<div class="listRight_sji2">
									<div class="right">
										<input type="text" placeholder="請輸入" v-model.trim="from.mobileCode" />
									</div>
									<div class="right2" @click="CodeBln ? clickNumberCodeFnc() : ''" v-html="codeMsg">
									</div>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">電郵</div>
							<div class="listRight">
								<input type="text" placeholder="請輸入" v-model.trim="from.mail" />
							</div>
						</div>
					</li>
					</template>
					<template v-if="schoolCode">
            <li>
              <div class="list">
                <div class="listLeft">學生編號</div>
                <div class="listRight" style="display: flex">
                  <input
                    type="text"
                    placeholder="請輸入學生編號"
                    v-model.trim="from.studentCode"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="list2">
                <div class="list2_uls">
                  <div class="listLeft">登錄密碼</div>
                  <div class="listRight">
                    <input
                      type="text"
                      placeholder="請輸入登錄密碼"
                      v-model.trim="from.password"
                    />
                  </div>
                </div>
                <div class="beizhu">*比賽APP登錄時使用</div>
              </div>
            </li>
          </template>
					<li>
						<div class="list2">
							<div class="list2_uls">
								<div class="listLeft">年級</div>
								<div class="listRight">
									<div class="listRight_xuan" @click="schoolBut">
										<span
											:class="from.grade == '請選擇' ? '' : 'placeholderValue'">{{ this.from.grade }}</span>
										<span class="listRight_icon"
											:class="{ '': true, listRight_icontransform:showPickerthree }">
											<van-icon name="arrow-down" size="12" color="#333333" />
										</span>
									</div>
								</div>
							</div>

							<div class="beizhu">*選擇後即代表報名對應參賽組別</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">學校所在地區</div>
							<div class="listRight">
								<div v-if="!schoolCode" class="listRight_xuan" @click="showPickerschool = true">
									<!-- v-model.trim="from.school_address" -->
									<span :class="newAddress.length == 0 ? '' : 'placeholderValue' "
										v-if="newAddress.length != 0 ">{{newAddress[0]}}>{{ newAddress[1]}}</span>
									<span v-else>請選擇</span>
									<span class="listRight_icon"
										:class="{ '': true, listRight_icontransform: showPicker }">
										<van-icon name="arrow-down" size="12" color="#333333" />
									</span>
								</div>
								<div v-if="schoolCode" class="listRight_xuan">
									<!-- v-model.trim="from.school_address" -->
									<span :class="newAddress.length == 0 ? '' : 'placeholderValue' "
										v-if="newAddress.length != 0 ">{{newAddress[0]}}>{{ newAddress[1]}}</span>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="list">
							<div class="listLeft">就讀學校</div>
							<div class="listRight listPan">
								<input v-if="!schoolCode" type="text" placeholder="請輸入" v-model.trim="searchCampus" @click.stop="getHkSchoolList()" />
								<input v-if="schoolCode" type="text" placeholder="請輸入" v-model.trim="searchCampus" disabled />

								<div class="posText" v-if="schoolNameList.length != 0">
									<ul>
										<li v-for="(item,index) in schoolNameList" :key="index" @click.stop="zhiyin(item)">{{ item.schoolName}}</li>
									</ul>
								</div>
							</div>
						</div>
					</li>
				</ul>

				<div class="uls_beizhu">* 如學校/機構 進行統一報名參賽，請登入官方網站 https://speech.chineserd.com<br>* 特殊學校-閃亮組 請通過學校老師進行統一報名<br>如有疑問，請聯絡【人工諮詢】，謝謝！</div>
			</div>

			<!-- 性别 -->
			<van-popup v-model="showPicker" position="bottom">
				<van-picker show-toolbar :columns="sexColumns" @confirm="onSexConfirm" @cancel="showPicker = false"
					confirm-button-text="確認" />
			</van-popup>
			<!-- code -->
			<van-popup v-model="showPickerTwo" position="bottom">
				<van-picker show-toolbar :columns="mobilePhoneColumns" @confirm="onPhoneConfirm"
					@cancel="showPickerTwo = false" confirm-button-text="確認" />
			</van-popup>
			<!-- 年级 -->
			<van-popup v-model="showPickerthree" position="bottom">
				<van-picker show-toolbar :columns="gradeColumns" @confirm="ongradeConfirm"
					@cancel="showPickerthree = false" confirm-button-text="確認" />
			</van-popup>

			<van-popup v-model="showPickerschool" position="bottom">
				<van-picker show-toolbar :columns="diqu" @cancel="showPickerschool = false" @change="onAddressChange"
					@confirm="onAddressConfirm" confirm-button-text="確認" />
			</van-popup>

			<div class="fromButtom" >
				<img :src="require('@/assets/image/hodgepodge/wabutom.png')" alt="" @click="whatapp"/>
				<img :src="require('@/assets/image/hodgepodge/tijiao.png')" alt=""  @click="Frombuttom"/>
				<!-- <div class="timeUp">報名已結束</div> -->
			</div> 
		</div> 
	</section>
</template>

<script>
	import headers from "@/components/header/custom";
	import publics from '@/mixin/public_mixin';
	import home from '@/mixin/home_mixin';
	export default {
		name: "views",
		data() {
			return {
				showPickerschool: false,
				diqu: [],
				cities: {},
				from: {
					username: "",
					sex: "請選擇參賽者性别", //-1 未选中  0男  1女
					code: "+852", //区号
					mobilePhone: "", //手机号
					mobileCode: "", //手机验证码
					grade: "選擇參賽者年級", //年级
					mail: "", //邮件
					school_address: "", //地区
					schoolName: "", //就读学校
				},
				showPicker: false,
				showPickerTwo: false,
				showPickerthree: false,

				gradeColumns: [
					"選擇參賽者年級",
					'K1',
					"K2",
					"K3",
					"P1",
					"P2",
					"P3",
					"P4",
					"P5",
					"P6",
					"S1",
					"S2",
					"S3",
				], //年级
				groups: [{
						code: 1,
						name: '幼稚園初級組（K1-K2）'
					},
					{
						code: 2,
						name: '幼稚園高級組（K3）'
					},
					{
						code: 3,
						name: '小學初級組（P1-P2）'
					},
					{
						code: 4,
						name: '小學中級組（P3-P4）'
					},
					{
						code: 5,
						name: '小學高級組（P5-P6）'
					},
					{
						code: 6,
						name: '初中組（S1-S3）'
					}
				],
				competitionGroup: '',
				groupId: '',


				sexColumns: ["請選擇參賽者性别", "男", "女"],
				mobilePhoneColumns: ["+852", "+86"], //区号

				CodeBln: true,
				timer: null, //清空时间
				countdown: 60,
				codeMsg: '獲取驗證碼',

				newAddress: [], //地區
				schoolNameList: [], //提示 
				schoolNameShow: true, //點擊
      	searchCampus: '',
      	disableWatch: false,
				schoolCode:'',
				competitionAccountId:'',
				// schoolCode:'578126',
      	// competitionAccountId: 'afG6Q3',
			};
		},
		computed: {},
		components: {
			headers,
		},
		watch: {
			newAddress(val) {
				if (val) {
					console.log(val)
					this.schoolNameList = []
					this.from.schoolName = ''
					this.searchCampus = ''
				}
			},
    searchCampus: {
      immediate: true,
      handler(val) {
        if (!this.disableWatch) {
          this.from.schoolName = ''
          if (this.newAddress.length != 0) {
            if (val.length != 0) {
              this.getHkSchoolList(val)
            } else {
              this.schoolNameList = []
              this.getHkSchoolList()
            }
          }
        } else {
          this.disableWatch = false
        }
      }
    },
		},
		created() {
			this.$router.replace('/')
			this.$toast.fail({
				message: '報名已結束',
				duration: 500,
				forbidClick: true
			})
			return
			this.getAddressList();
			// 从地址栏中获取schoolCode参数
			const urlParams = new URLSearchParams(window.location.search)
			const schoolCode = urlParams.get('schoolCode')
			const competitionAccountId = urlParams.get('id')
			console.log(schoolCode,competitionAccountId, 'schoolCode')
			if (schoolCode) {
				// 在本地存储schoolCode
				this.schoolCode = schoolCode
				this.competitionAccountId = competitionAccountId
				// 发请求获取对应校区
				this.getSchoolData()
			}
		},
		mixins: [publics,home],
		mounted() {},
		methods: {
    getSchoolData() {
      const params = {
        schoolNo: this.schoolCode
      }
      this.$axios({
        url: this.$api.getSchoolByCode,
        method: 'get',
        params: params
      }).then((response) => {
        const { data } = response.data
        if (response.data.code == 0) {
          const { schoolRegion, schoolRegion1, schoolName } = data
          this.newAddress[0] = schoolRegion
          this.newAddress[1] = schoolRegion1
          // this.schoolNameList = [{ schoolName }]
          this.disableWatch = true
          this.from.schoolName = schoolName
          this.searchCampus = schoolName
        }
      })
    },
			// 處理ios軟鍵盤彈出頁面被頂上去兼容問題
			onBlurInput() {
				window.scroll(0, 0);
			},
			whatapp(){  
				this.home_mdevInit();
				window.open(this.whatUrl, "_blank");
			},
			getQueryString: function(name) {
				return (
					decodeURIComponent(
						(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
							location.href
						) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			zhiyin(e) {
				this.disableWatch = true
				this.searchCampus = e.schoolName
				this.$nextTick(() => {
					this.from.schoolName = e.schoolName
					this.schoolNameList = []
				})
			},
			onAddressChange(picker, value, index) {
				picker.setColumnValues(1, this.cities[value[0]]);
				this.newAddress = value;
			},
			onAddressConfirm(e) {
				console.log(e)
				this.newAddress = e;
				this.showPickerschool = false;
			},
			getAddressList() {
				this.$axios
					.get(this.$api.getHkSchoolRegion)
					.then(res => {
						console.log(res)
						if (res.data.code == 0) {
							let data = res.data.data;
							const cities = data.reduce((result, {
								title,
								data
							}) => {
								result[title] = data;
								return result;
							}, {});
							console.log(cities)
							this.cities = cities;
							this.diqu = [{
								values: Object.keys(cities)
							}, {
								values: cities['新界東區域']
							}]
						}
					})
					.catch((res) => {
						console.log('失败', res)
					})
			},
			getHkSchoolList(e) { 
				let params = {
					schoolRegion: this.newAddress[0],
					schoolRegion1: this.newAddress[1],
					schoolName: e
				}
				this.$axios({
						url: this.$api.getHkSchool,
						method: 'get',
						params: params
					})
					.then(res => {
						console.log(res)
						let data = res.data.rows
						if (res.data.code == 0) {
							this.schoolNameList = [];
							this.schoolNameList = data
						}
					})
					.catch((res) => {
						console.log('失败', res)
					})
			},
			validator_phone(val) {
				const phone = val
				return /^[0-9]*$/.test(phone)
			},
			// 验证码
			clickNumberCodeFnc() {
				let {
					code,
					mobilePhone
				} = this.from;
				console.log(code, mobilePhone)
				if (mobilePhone == null || mobilePhone == '') {
					this.$toast.fail('請檢查您的手機號')
					return;
				}

				if (!this.validator_phone(mobilePhone)) {
					this.$toast('手機號格式有誤 !')
					return
				}
				const phoneList = []
				phoneList.push(code + '-' + mobilePhone)
				const params = {
					appType: 12,
					verifyType: 9,
					phoneList,
					smsTemplateType: 10,
					signType: 1
				}

				this.$toast.loading({
					message: '正在獲取中...',
					forbidClick: true,
					duration: 0
				});

				this.$axios({
						url: this.$api.sendSmsForSecondCup,
						method: 'post',
						data: params
					})
					.then((response) => {
						if(response.data.code == 0){
							this.$toast.clear();
							this.$toast.success('驗證碼获取成功');
							let that = this
							clearInterval(that.timeIntinvel)
							that.CodeBln = false
							let index = 60

							that.timeIntinvel = setInterval(() => {
								index--
								if (index <= 0) {
									index = 0
									that.CodeBln = true
									that.codeMsg = '獲取驗證碼'
								} else {
									that.codeMsg = `${index}S`
								}
							}, 1000)
						}else{
							this.$toast.fail('驗證碼获取失败');
						}
						
					})
					.catch((error) => {
						this.$toast.clear();
						//请求失败
						this.$toast.fail('error')
						console.log(error)
					})
			},
			// 電郵驗證
			validator_email(val) {
				const email =val.trim(); 
				return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(email);
			},
			// 表單
			Frombuttom() {
				// console.log('報名成功') 
				let {
					username,
					sex,
					code,
					mobilePhone,
					mobileCode,
					grade,
					mail,
					school_address,
					schoolName,
          studentCode,
          password
				} = this.from;

				if (username == '' || username == null) {
					this.$toast.fail('請檢查參賽者姓名')
					return
				}
				if (sex == '請選擇參賽者性别' || sex == -1) {
					this.$toast.fail('請選擇參賽者性别')
					return
				}
				if (!this.schoolCode){
					if (mobilePhone == '' || mobilePhone == null) {
						this.$toast.fail('請輸入參賽者電話')
						return
					}

					if (!this.validator_phone(mobilePhone)) {
						this.$toast('手機號格式有誤 !')
						return
					}
					if (mobileCode == '' || mobileCode == null) {
						this.$toast.fail('請輸入驗證碼')
						return
					}

					if (mail == '' || mail == null) {
						this.$toast.fail('請輸入電郵')
						return
					}
					if (!this.validator_email(mail)) {
						this.$toast('電郵格式有誤 !');
						return;
					}
				}else{
					if (!studentCode) {
            this.$toast.fail('請輸入學生編號')
            return
          }
          if (!password) {
            this.$toast.fail('請輸入登錄密碼')
            return
          }
				}
				if (grade == '選擇參賽者年級' || grade == null) {
					this.$toast.fail('選擇參賽者年級')
					return
				}


				if (this.newAddress.length == 0) {
					this.$toast.fail('選擇學校地區')
					return
				}

				if (schoolName == '' || schoolName == null) {
					this.$toast.fail('請輸入就讀學校')
					return
				}


				const params = {
					studentName: username,
					sex,
					phone: mobilePhone,
					grade: grade,
					prefix: code.replace(/\+/g, ''),
					email: mail,
					verifyCode: mobileCode,
					studentRegion: this.newAddress[0] + '>' + this.newAddress[1],
					currentSchool: schoolName,
					competitionGroup: this.competitionGroup,
					groupId: this.groupId,
          studentCode,
          password,
          schoolCode: this.schoolCode,
          competitionAccountId: this.competitionAccountId,
          // platform: 'H5',
					platform: !localStorage.getItem('c') ? 'H5' : localStorage.getItem('c')
				}

				console.log('提交', params)
				this.$toast.loading({
					message: '正在提交中...',
					forbidClick: true,
					duration: 0
				});

				this.$axios({
						url: this.schoolCode
            ? this.$api.signUpByStudentCode
            : this.$api.fromSaveSingle,
						method: 'post',
						data: params
					})
					.then((response) => {
						console.log(response)
						if (response.data.code == 0) {
							this.$toast.clear();
							this.$toast.success('提交成功');
							this.$router.replace('/successful?isShool=true')
						} else {
							this.$toast.clear();
							this.$toast.fail(response.data.msg)
						}
					})
					.catch((error) => {
						//请求失败
						this.$toast.clear();
						this.$toast.fail('error')
						console.log(error)
					})
				// this.$router.push('/successful')
			},
			onSexConfirm(e) {
				console.log(e);
				this.from.sex = e;
				this.showPicker = false;
			},
			sexBut() {
				this.showPicker = true;
			},
			schoolBut() {
				this.showPickerthree = true;
			},
			pickerPhoneBut() {
				this.showPickerTwo = true;
			},
			onPhoneConfirm(e) {
				console.log(e);
				this.from.code = e;
				this.showPickerTwo = false;
			},
			ongradeConfirm(e) {
				console.log(e);
				this.from.grade = e;
				this.showPickerthree = false;
				switch (this.from.grade) {
					case 'K1':
						this.groupId = this.groups[0].code
						this.competitionGroup = this.groups[0].name
						break
					case 'K2':
						this.groupId = this.groups[0].code
						this.competitionGroup = this.groups[0].name
						break
					case 'K3':
						this.groupId = this.groups[1].code
						this.competitionGroup = this.groups[1].name
						break
					case 'P1':
						this.groupId = this.groups[2].code
						this.competitionGroup = this.groups[2].name
						break
					case 'P2':
						this.groupId = this.groups[2].code
						this.competitionGroup = this.groups[2].name
						break
					case 'P3':
						this.groupId = this.groups[3].code
						this.competitionGroup = this.groups[3].name
						break
					case 'P4':
						this.groupId = this.groups[3].code
						this.competitionGroup = this.groups[3].name
						break
					case 'P5':
						this.groupId = this.groups[4].code
						this.competitionGroup = this.groups[4].name
						break
					case 'P6':
						this.groupId = this.groups[4].code
						this.competitionGroup = this.groups[4].name
						break
					case 'S1':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					case 'S2':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					case 'S3':
						this.groupId = this.groups[5].code
						this.competitionGroup = this.groups[5].name
						break
					default:
						console.log('未匹配')
						this.groupId = ''
						this.competitionGroup = ''
						break
				}
				console.log(this.groupId, this.competitionGroup)
			},
		},
	};
</script>

<style lang="scss" scoped>
	.views {
		width: 100%;
		//height: 100%;
		height: 100vh;
		// : hidden;
		// width: 100%;
		display: flex;
		flex-direction: column;
		// ovoverflowerflow: hidden;
		// align-items: flex-start;
		// flex-direction: column;
		// justify-content: flex-start; 

		.views_content {
			width: 100%;
			height: calc(100vh - 0.8rem);
			padding-bottom: 4rem;
			box-sizing: border-box;
		   overflow-x: hidden;
		    background: #fafafa;
			position: relative;

			.views_content_box {
				padding: 0.32rem;
				box-sizing: border-box;

				ul {
					width: 100%;
					padding: 0 0.32rem;
					box-sizing: border-box;
					background: #ffffff;
					border-radius: 0.16rem;
					// overflow: hidden;

					li:last-child {
						border-bottom: none !important;
					}

					li {
						width: 100%;
						border-bottom: 0.02rem solid #f2f2f2;
						display: flex;

						.list {
							width: 100%;
							height: 1.04rem;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.listLeft {
								font-size: 0.28rem;
								font-family: PingFang SC-Regular, PingFang SC;
								font-weight: 400;
								color: #333333;
							}

							.listRight {
								width: 70%;
								height: 100%;
								display: flex;

								input {
									width: 100%;
									height: 100%;
									font-size: 0.28rem;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;
									color: #000000;
									border: none;
									text-align: right;
									background: none;
									padding: 0;
									box-sizing: border-box;
								}

								input::-webkit-input-placeholder {
									color: #CCCCCC;
								}

								/* 使用webkit内核的浏览器 */
								input:-moz-placeholder {
									color: #CCCCCC;
								}

								/* Firefox版本4-18 */
								input::-moz-placeholder {
									color: #CCCCCC;
								}

								/* Firefox版本19+ */
								input:-ms-input-placeholder {
									color: #CCCCCC;
								}

								/* IE浏览器 */

								.listRight_xuan {
									width: 100%;
									height: 100%;
									display: flex;
									align-items: center;
									text-align: right;
									justify-content: flex-end;
									color: #CCCCCC;
									font-size: 0.28rem;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;

									.placeholderValue {
										color: #000000 !important;
									}

									.listRight_icon {
										margin-left: 0.1rem;
									}

									.listRight_icontransform {
										transform: rotate(180deg);
									}
								}

								.listRight_sji {
									width: 100%;
									height: 100%;
									display: flex;
									align-items: center;
									text-align: right;
									justify-content: flex-end;
									color: #CCCCCC;
									font-size: 0.28rem;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;

									.left {
										font-size: 0.28rem;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;
										color: #333333;
										height: 100%;
										display: flex;
										align-items: center;
										position: relative;
										padding-right: 0.32rem;
										box-sizing: border-box;

										span:nth-child(1) {
											font-size: 0.28rem;
											font-family: PingFang SC-Regular, PingFang SC;
											font-weight: 400;
											color: #333333;
											margin-right: 0.08rem;
										}

										.left_formTwo {
											transform: rotate(180deg);
										}
									}

									.left:after {
										content: " ";
										width: 0.01rem;
										height: 50%;
										background: #cccccc;
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										margin: auto;
									}

									.right {
										padding-left: 0.32rem;
										box-sizing: border-box;
										width: 2.5rem;
										height: 100%;
									}
								}

								.listRight_sji2 {
									width: 100%;
									height: 100%;
									display: flex;
									align-items: center;
									text-align: right;
									justify-content: flex-end;
									color: #CCCCCC;
									font-size: 0.28rem;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;

									.right {
										font-size: 0.28rem;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;
										color: #333333;
										height: 100%;
										display: flex;
										align-items: center;
										position: relative;
										padding-right: 0.32rem;
										box-sizing: border-box;
									}

									.right2 {
										font-size: 0.28rem;
										font-family: PingFang SC-Semibold, PingFang SC;
										font-weight: 600;
										color: #52a1ff;
										display: flex;
										align-items: center;
										width: 2rem;
										text-align: right;
										justify-content: space-evenly;
									}

									.right:after {
										content: " ";
										width: 0.01rem;
										height: 50%;
										background: #cccccc;
										position: absolute;
										top: 0;
										right: 0;
										bottom: 0;
										margin: auto;
									}
								}
							}

							.listPan {
								position: relative;
								
								input{
									display: flex;
								}

								.posText {
									position: absolute;
									height: 1px;
									bottom: 0;
									right: 0;
									width: 100%;
									margin: auto;
									z-index: 9;

									ul {
										width: 100%;
										padding: 0 !important;
										box-sizing: 0 1px 3px rgba(31, 35, 40, 0.12), 0 8px 24px rgba(66, 74, 83, 0.12);
										border: 1px solid #F2F2F2;
										max-height: 4rem;
                  	overflow-y: auto;
										li {
											height: 0.8rem;
											line-height: 0.8rem;
											text-align: left;
											padding: 0 0.24rem;
											box-sizing: border-box;
											cursor: pointer;
											overflow: hidden;
											font-size: 0.24rem;
										}

										li:hover {
											background: #52A1FF;
											color: #ffffff
										}
									}
								}
							}
						}

						.list2 {
							width: 100%;

							.list2_uls {
								width: 100%;
								height: 1.04rem;
								display: flex;
								align-items: center;
								justify-content: space-between;

								.listLeft {
									font-size: 0.28rem;
									font-family: PingFang SC-Regular, PingFang SC;
									font-weight: 400;
									color: #333333;
								}

								.listRight {
									width: 70%;
									height: 100%;
									display: flex;

									input {
										width: 100%;
										height: 100%;
										font-size: 0.28rem;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;
										color: #000000;
										border: none;
										text-align: right;
										background: none;
										padding: 0;
										box-sizing: border-box;
									}

									input::-webkit-input-placeholder {
										color: #CCCCCC;
									}

									/* 使用webkit内核的浏览器 */
									input:-moz-placeholder {
										color: #CCCCCC;
									}

									/* Firefox版本4-18 */
									input::-moz-placeholder {
										color: #CCCCCC;
									}

									/* Firefox版本19+ */
									input:-ms-input-placeholder {
										color: #CCCCCC;
									}

									/* IE浏览器 */

									.listRight_xuan {
										width: 100%;
										height: 100%;
										display: flex;
										align-items: center;
										text-align: right;
										justify-content: flex-end;
										color: #CCCCCC;
										font-size: 0.28rem;
										font-family: PingFang SC-Regular, PingFang SC;
										font-weight: 400;

										.placeholderValue {
											color: #000000 !important;
										}

										.listRight_icon {
											margin-left: 0.1rem;
										}

										.listRight_icontransform {
											transform: rotate(180deg);
										}
									}
								}
							}

							.beizhu {
								width: 100%;
								font-size: 0.26rem;
								line-height: 0.3rem;
								font-family: PingFang SC-Regular, PingFang SC;
								font-weight: 400;
								color: #E80202;
								margin-top: 0.16rem;
								text-align: left;
								padding-bottom: 0.18rem;
								box-sizing: border-box;
								
							}
						}
					}
				}

				.uls_beizhu {
					font-size: 0.26rem;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #E80202;
					line-height: 0.34rem;
					text-align: left;
					margin-top: 0.32rem;
				}
			}
		}

		.fromButtom {
			
			position: fixed;
			bottom: 2%;
			left: 0;
			right: 0;
			padding: 0 0.32rem;
			box-sizing: border-box; 
			width: 100%;
			// height:300px;
			display: flex;
			justify-content: center;


			img:nth-child(1){
				width: 131px;
				height: 100%;
				margin-right: 12px;
			}

			img:nth-child(2){
				width:200px;
				height: 100%;
			}
			.timeUp {
				width: 200px;
				height: 50px;
				background: #d0d0d0;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				font-size: 20px;
				font-family: PingFang SC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ffffff;
				box-shadow: 0px 4px 9px 0px rgba(208, 208, 208, 0.4);
				border-radius: 25px;
			}
		}
	}
</style>
